<template>
  <div>
    <!--begin::Dashboard-->
    <component :is="loadComp" />
    <!--end::Dashboard-->
  </div>
</template>

<script>
export default {
  name: "dashboard",
  computed: {
    loadComp() {
      if (this.$store.getters.currentRole.includes("LOGISTICS") || this.$store.getters.currentRole.includes("ADMIN")) {
        return () => import(`@/view/pages/logistics/dashboard/Dashboard`);
      }

      if (this.$store.getters.currentRole.includes("CLIENT")) {
        return () => import(`@/view/pages/client/dashboard/Dashboard`);
      }

      return "";
    }
  }
};
</script>
